import { leftPadTo } from "./strings";

export function formatFloat(decimal: number, places: number) {
  if (isNaN(decimal)) return 0;
  return Number.parseFloat(`${Math.round(Number.parseFloat(`${decimal}e+${places}`))}e-${places}`);
}

export function isNumeric(str: string): boolean {
  return /^-?\d+$/.test(str);
}

/**
 * options for roundToDecimalPlaces
 */
export type RoundToDecimalPlacesOptions<NUMERIC extends boolean> = {
  /**
   * If true, the function will return as a number
   */
  numeric?: NUMERIC;
  /**
   * The number of decimal places to round to if > -1 and < 1
   */
  lessThanOneDecimals?: number;
};

/**
 * Returns a string (or number if `numeric` is true) rounded to the specified number of decimals.
 * @param value The number to round
 * @param decimals The number of decimals to round to
 * @param options Additional options
 * @returns A string (or number) of `value` rounded to the specified decimal points
 */
export const roundToDecimalPlaces = <NUMERIC extends boolean>(
  value: number,
  decimals: number,
  options: RoundToDecimalPlacesOptions<NUMERIC> = {}
): NUMERIC extends true ? number : string => {
  const { numeric, lessThanOneDecimals = decimals } = options;
  const useDecimals = value > -1 && value < 1 ? lessThanOneDecimals : decimals;
  const tensPlaces = Math.pow(10, useDecimals);
  const str = (Math.round(value * tensPlaces) / tensPlaces).toFixed(useDecimals);
  return (numeric ? Number(str) : str) as NUMERIC extends true ? number : string;
};

export default [formatFloat];

export const addLeadingZeroes = (num: number, minSize: number) => leftPadTo(`${num}`, minSize, "0");

export const isEven = (num: number) => Math.round(num / 2) === num / 2;
export const isOdd = (num: number) => !isEven(num);
